import React from 'react';
import '../../styles/pages/ac-info.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Books from '../../images/library.jpg';
import AcLandscape from '../../images/ac-landscape.jpg';

// Components
import Layout from '../layout';
// import PostCard from '../post-card';
import Header from '../header';
import Sidebar from '../sidebar';

const AcInfo = (props) => {
  const {
    pageContext: { langKey },
  } = props;

  const viewmorePath = (lang) => {
    switch (lang) {
      case 'en':
        return '/en/news';
      case 'zh':
        return '/news';
      default:
        return null;
    }
  };
  return (
    <Layout>
      <Container>
        <Header title="Air Conditioning Informaiton" />
        <div className="AcInfo__banner">
          <img src={Books} width="100%" style={{ opacity: '.8' }} alt="books" />
        </div>
        <Row>
          <Col lg={9}>
            <div className="AcInfo__intro">
              <img src={AcLandscape} width="100%" alt="ac" />
              <p className="AcInfo__intro--title">Introduction to Air Conditioning &rarr;</p>
            </div>
            <div className="AcInfo__intro">
              <img src={AcLandscape} width="100%" alt="ac" />
              <p className="AcInfo__intro--title">Introduction to Cooling System &rarr;</p>
            </div>
            <Header title="Hot Posts" viewmore path={viewmorePath(langKey)} />
            {/* <Row> <PostCard md={6} lg={6} />
              <PostCard md={6} lg={6} /> </Row> */}
            <p>We are still working on it!</p>
          </Col>
          <Col lg={3} className="d-none d-lg-block">
            <Sidebar />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default AcInfo;
