import React from 'react';

const Sidebar = () => {
  return (
    <aside>
      {/* <input
        placeholder="Search"
        style={{
          width: '100%',
          border: '1px solid rgba(0,0,0,.15)',
          background: 'transparent',
          padding: '4px',
          marginBottom: '8px',
        }}
      /> */}

      <div>
        <p
          style={{
            fontWeight: 'bold',
            borderBottom: '1px solid rgba(0,0,0,.2)',
            paddingBottom: '5px',
            marginBottom: '10px',
          }}
        >
          Category
        </p>
        {/* <ul style={{ listStyle: 'none', padding: '0' }}>
          <li style={{ color: '#666' }}>Products</li>
          <li style={{ color: '#666' }}>Tech posts</li>
        </ul> */}
      </div>
    </aside>
  );
};

export default Sidebar;
