import React from 'react';
import '../styles/header.css';
import { Link } from 'gatsby';

const Header = ({ title, viewmore, path }) => {
  return (
    <h2 className="Header">
      <span>{title}</span>
      {viewmore && (
        <Link to={path}>
          <span className="Header--viewmore">View more</span>
        </Link>
      )}
    </h2>
  );
};

export default Header;
